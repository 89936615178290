import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import AdsSection from "./AdsSection"
import { Accordion, Button, Card, Col, Row } from "react-bootstrap"
import sinDatos from "../assets/images/sin_datos.svg"

const GameDetails = ({ gameDetails }) => {
  const [tabActive, setTabActive] = useState("modulesSection")
  if (typeof window !== `undefined`) {
    let starterSection = window.location.hash
    if (starterSection && starterSection.replace("#", "") !== tabActive) {
      starterSection = starterSection.replace("#", "")
      setTabActive(starterSection)
    }
  }
  console.log(gameDetails)
  const changeActive = (e) => {
    //Clean active selection
    document.getElementById("modulesSection").classList.remove("active")
    document.getElementById("helpsSection").classList.remove("active")
    document.getElementById("characterSheetsSection").classList.remove("active")
    document.getElementById(e.target.id).classList.add("active")
    setTabActive(e.target.id)
  }
  const transformArray = (el) => {
    debugger;
    let cleanString = el.replaceAll("[", "").replaceAll("]", "")
    let arrayElements = cleanString.split(",")
    let elements = []
    for (let i = 0; arrayElements.length < i; i++) {
      elements.push("<label>" + arrayElements[i] + "</label>")
    }
    return elements
  }
  return (
    <section className="blog-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details__content">
              <div className="course-details__top">
                 <span className="blog-one__text">
                  <div dangerouslySetInnerHTML={{ __html: gameDetails.description }} />
                </span>
              </div>
              <Accordion defaultActiveKey="0">
                {gameDetails.editions.map(edition => (
                  <Card key={edition.uuid}>
                    <Accordion.Toggle as={Card.Header} eventKey={edition.uuid}>
                      {edition.name}
                      <a style={{ float: "right" }}
                         href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/ed/" + edition.uuid}
                         rel="noopener noreferrer" download>
                        <Button variant="primary"
                        > Descargar </Button>{" "}
                      </a>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={edition.uuid}>
                      <Card.Body>
                        <Row>
                          <Col> Autor: </Col>
                          <Col>
                            {edition.author ? (edition.author.replace("[", "").replace("]", "").split(",").map(editorial => (editorial)))
                              :
                              "¡Vaya! no tenemos este dato"
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col> Editorial: </Col>
                          <Col>
                            {edition.editorials ? (edition.editorials.replace("[", "").replace("]", "").split(",").map(editorial => (editorial)))
                              :
                              "¡Vaya! no tenemos este dato"
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col> Año: </Col>
                          <Col> {edition.year ? (edition.year) : "¡Vaya! no tenemos este dato"} </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>

              <div className="share-block">
                <div className="left-block">
                  <p>
                    Compartir:
                  </p>
                </div>
                <div className="social-block">
                  <a target="_blank" rel="noreferrer"
                     href={"https://twitter.com/intent/tweet?text=https://rolteca.com/" + gameDetails.slug}>
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a target="_blank" rel="noreferrer"
                     href={"https://www.facebook.com/dialog/share?app_id=1035471519867457&display=popup&href=https%3A%2F%2Frolteca.com%2F%2F" + gameDetails.slug}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a target="_blank" rel="noreferrer"
                     href={"https://telegram.me/share/url?url=https://rolteca.com" + gameDetails.slug}>
                    <i className="fab fa-telegram"></i>
                  </a>
                </div>
              </div>

              <ul className="course-details__tab-navs list-unstyled nav nav-tabs">
                <li onClick={changeActive}>
                  <a
                    id="modulesSection"
                    className={tabActive === "modulesSection" ? "active" : null}
                    role="tab"
                    data-toggle="tab"
                    href="#modulesSection"
                  >
                    Módulos
                  </a>
                </li>
                <li onClick={changeActive}>
                  <a
                    id="helpsSection"
                    className={tabActive === "helpsSection" ? "active" : null}
                    role="tab"
                    data-toggle="tab"
                    href="#helpsSection"
                  >
                    Ayudas
                  </a>
                </li>
                <li onClick={changeActive}>
                  <a id="characterSheetsSection"
                     className={tabActive === "characterSheetsSection" ? "active" : null}
                     role="tab"
                     data-toggle="tab"
                     href="#characterSheetsSection">
                    Fichas PJ
                  </a>
                </li>
              </ul>
              <div className="tab-content course-details__tab-content ">
                {tabActive === "modulesSection" ?
                  (
                    <div
                      className="tab-pane show active  animated fadeInUp"
                      role="tabpanel"
                      id="modules"
                    >
                      {gameDetails.modules.length === 0 ?
                        (
                          <div>
                            <span>¡Vaya! parece que no tenemos módulos para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                            <div className="row justify-content-md-center">
                              <div className="col col-lg-6">
                                <img src="" alt={gameDetails.name.toLowerCase()} srcSet={sinDatos} />
                              </div>
                            </div>
                          </div>
                        )
                        :
                        (
                          <div>
                            <h2>Módulos para <strong> {gameDetails.name.toLowerCase()} </strong></h2>
                            <Accordion defaultActiveKey="0">
                              {gameDetails.modules.map(module => (
                                <Card key={module.uuid}>
                                  <Accordion.Toggle as={Card.Header} eventKey={module.uuid}>
                                   <h3 className="h3-element-name"> {module.name} </h3>
                                    <a style={{ float: "right" }}
                                       href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/md/" + module.uuid}
                                       rel="noopener noreferrer" download>
                                      <Button variant="primary"
                                      > Descargar </Button>{" "}
                                    </a>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={module.uuid}>
                                    <Card.Body>{module.description}</Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                            </Accordion>
                          </div>
                        )
                      }

                    </div>
                  ) :
                  (
                    <div
                      className="tab-pane  animated fadeInUp hide"
                      role="tabpanel"
                      id="modules"
                    >
                      {gameDetails.modules.length === 0 ?
                        (
                          <div>
                            <span>¡Vaya! parece que no tenemos módulos para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                            <div className="row justify-content-md-center">
                              <div className="col col-lg-6">
                                <img src="" alt={gameDetails.name.toLowerCase()} srcSet={sinDatos} />
                              </div>
                            </div>
                          </div>
                        )
                        :
                        (
                          <div>
                            <h2>Módulos para <strong> {gameDetails.name.toLowerCase()} </strong></h2>
                            <Accordion defaultActiveKey="0">
                              {gameDetails.modules.map(module => (
                                <Card key={module.uuid}>
                                  <Accordion.Toggle as={Card.Header} eventKey={module.uuid}>
                                    <h3 className="h3-element-name"> {module.name} </h3>
                                    <a style={{ float: "right" }}
                                       href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/md/" + module.uuid}
                                       rel="noopener noreferrer" download>
                                      <Button variant="primary"
                                      > Descargar </Button>{" "}
                                    </a>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={module.uuid}>
                                    <Card.Body>{module.description}</Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                            </Accordion>
                          </div>
                        )
                      }

                    </div>
                  )

                }
                {tabActive === "helpsSection" ?
                  (
                    <div
                      className="tab-pane show active  animated fadeInUp"
                      role="tabpanel"
                      id="helps"
                    >
                      {gameDetails.helps.length === 0 ?
                        (
                          <div>
                            <span>¡Vaya! parece que no tenemos ayudas para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                            <div className="row justify-content-md-center">
                              <div className="col col-lg-6">
                                <img src="" alt={gameDetails.name.toLowerCase()} srcset={sinDatos} />
                              </div>
                            </div>
                          </div>
                        )
                        : (
                          <Accordion defaultActiveKey="0">
                            <h2> Ayudas para <strong>{gameDetails.name.toLowerCase()}</strong></h2>
                            {gameDetails.helps.map(help => (
                              <Card key={help.uuid}>
                                <Accordion.Toggle as={Card.Header} eventKey={help.uuid}>
                                  <h3 className="h3-element-name">{help.name}</h3>
                                  <a style={{ float: "right" }}
                                     href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/hd/" + help.uuid}
                                     rel="noopener noreferrer" download>
                                    <Button variant="primary"
                                    > Descargar </Button>{" "}
                                  </a>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={module.uuid}>
                                  <Card.Body>{module.description}</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>

                        )}
                    </div>
                  ) :
                  (
                    <div
                      className="tab-pane  animated fadeInUp "
                      role="tabpanel"
                      id="helps"
                    >
                      {gameDetails.helps.length === 0 ?
                        (
                          <div>
                            <span>¡Vaya! parece que no tenemos ayudas para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                            <div className="row justify-content-md-center">
                              <div className="col col-lg-6">
                                <img src="" alt={gameDetails.name.toLowerCase()} srcSet={sinDatos} />
                              </div>
                            </div>
                          </div>
                        )
                        : (
                          <Accordion defaultActiveKey="0">
                            <h2> Ayudas para <strong>{gameDetails.name.toLowerCase()}</strong></h2>
                            {gameDetails.helps.map(help => (
                              <Card key={help.uuid}>
                                <Accordion.Toggle as={Card.Header} eventKey={help.uuid}>
                                  <h3 className="h3-element-name">{help.name}</h3>
                                  <a style={{ float: "right" }}
                                     href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/hd/" + help.uuid}
                                     rel="noopener noreferrer" download>
                                    <Button variant="primary"
                                    > Descargar </Button>{" "}
                                  </a>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={module.uuid}>
                                  <Card.Body>{module.description}</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>

                        )}
                    </div>
                  )}
                {tabActive === "characterSheetsSection" ?
                  (
                    <div
                      className="tab-pane show active  animated fadeInUp"
                      role="tabpanel"
                      id="charactersheets"
                    >
                      {
                        gameDetails.charactersheets.length === 0 ?
                        (
                          <div>
                            <span>¡Vaya! parece que no tenemos ayudas para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                            <div className="row justify-content-md-center">
                              <div className="col col-lg-6">
                                <img src="" alt={gameDetails.name.toLowerCase()} srcSet={sinDatos} />
                              </div>
                            </div>
                          </div>
                        )
                          :
                          (
                            <div>
                              <h2>Fichas de personaje para <strong> {gameDetails.name.toLowerCase()} </strong></h2>
                              <Accordion defaultActiveKey="0">
                                {gameDetails.charactersheets.map(charactersheet => (
                                  <Card key={charactersheet.uuid}>
                                    <Accordion.Toggle as={Card.Header} eventKey={charactersheet.uuid}>
                                      <h3 className="h3-element-name">{charactersheet.name}</h3>
                                      <a style={{ float: "right" }}
                                         href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/csd/" + charactersheet.uuid}
                                         rel="noopener noreferrer" download>
                                        <Button variant="primary"
                                        > Descargar </Button>{" "}
                                      </a>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={charactersheet.uuid}>
                                      <Card.Body>{charactersheet.description}</Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                ))}
                              </Accordion>
                            </div>
                          )
                      }
                       </div>
                  ) :
                  (
                    <div
                      className="tab-pane animated fadeInUp"
                      role="tabpanel"
                      id="charactersheets"
                    >
                      {
                        gameDetails.charactersheets.length === 0 ?
                          (
                            <div>
                              <span>¡Vaya! parece que no tenemos ayudas para <strong> {gameDetails.name.toLowerCase()} </strong></span>
                              <div className="row justify-content-md-center">
                                <div className="col col-lg-6">
                                  <img src="" alt={gameDetails.name.toLowerCase()} srcSet={sinDatos} />
                                </div>
                              </div>
                            </div>
                          )
                          :
                          (
                            <div>
                              <h2>Fichas de personaje para <strong> {gameDetails.name.toLowerCase()} </strong></h2>
                              <Accordion defaultActiveKey="0">
                                {gameDetails.charactersheets.map(charactersheet => (
                                  <Card key={charactersheet.uuid}>
                                    <Accordion.Toggle as={Card.Header} eventKey={charactersheet.uuid}>
                                      <h3 className="h3-element-name">{charactersheet.name}</h3>
                                      <a style={{ float: "right" }}
                                         href={"https://download.rolteca.com/download/" + gameDetails.uuid + "/csd/" + charactersheet.uuid}
                                         rel="noopener noreferrer" download>
                                        <Button variant="primary"
                                        > Descargar </Button>{" "}
                                      </a>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={charactersheet.uuid}>
                                      <Card.Body>{charactersheet.description}</Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                ))}
                              </Accordion>
                            </div>
                          )
                      }
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="course-details__price">

              {gameDetails.image ?
                <Img fluid={{ ...gameDetails.image.sharp.fluid }} alt={gameDetails.name} />
                :
                (<img src={"https://rolteca.com/covers/" + gameDetails.uuid + "..jpg"} alt={gameDetails.name} />)
              }
              <label>
                {gameDetails.name}
              </label>
            </div>

            <div className="course-details__meta">
              <AdsSection />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GameDetails
