import React from "react"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import Footer from "../components/Footer"
import BlogDetails from "../components/BlogDetails"
import Nav from "../components/Nav"
import { graphql } from "gatsby"
import ProjectDetails from "../components/ProjectDetails"
import GameDetails from "../components/GameDetails"

export const query = graphql`
    query($id: String!) {
        allStrapiGames(filter: {id: {eq: $id}}) {
            nodes {
                charactersheets {
                    name
                    uuid
                }
                editions {
                    name
                    uuid
                    year
                    editorials
                    authors
                }
                modules {
                    name
                    description
                    uuid
                }
                helps {
                    description
                    name
                    uuid
                }
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                description
                name
                uuid
            }
        }
    }
`

const ProjectDetailPage = ({ data }) => {
  const gamesContent = data.allStrapiGames.nodes[0]
  let image = "";
  if(gamesContent.image === null) {
    image = "https://rolteca.com/covers/" + gamesContent.uuid + "..jpg";
  }else{
    image = gamesContent.image.sharp.fluid.src;
  }
  return (
    <Layout
      title={"Rolteca | " + gamesContent.name}
      description={gamesContent.description}
      slug={gamesContent.slug}
      image={image}>
      <Nav />
      <PageHeader title={gamesContent.name} />
      <GameDetails
        gameDetails={gamesContent}/>
      <Footer />
    </Layout>
  )
}

export default ProjectDetailPage
